/* eslint-disable react/no-array-index-key */
import React from 'react'
import Box from '@lib/ui/core/Box'
import Typography from '@lib/ui/core/Typography'
import { TagLink } from '@components/Tag'
import { useStaticQuery, graphql } from 'gatsby'
import { parseSiteConfig } from '@services/prismic/helpers'
// import { linkResolver } from '@utils/link-resolver'

const Tag = ({ text,uid }) => (
  <Box display="inline" mr={1.25}>
    {/* <TagLink to={linkResolver(text)}> */}
    <TagLink to={`/tag/${uid}`}>
      {text}
    </TagLink>
  </Box>
)



const Popular = () => {

  const query = useStaticQuery(graphql`
    query {
      allPrismicSiteConfig {
        nodes {
          data {
            featured_tags {
              tag {
                document {
                  ... on PrismicTag {
                    id
                    data {
                      name
                    }
                    prismicId
                    uid
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const featuredTags = parseSiteConfig(query.allPrismicSiteConfig.nodes[0]).featured_tags;

  return <Typography component={Box} height="1.5rem" overflow="hidden">
    <Box display="flex" flexWrap="wrap" data-ele-section="hot-tag">
      <Box display="inline-block" mr={1.25}>
        <Typography>熱門:</Typography>
      </Box>
      {/* <TagList></TagList> */}
      {featuredTags.map((tag, i) => (
        <Tag text={featuredTags[i].tag.name} uid={featuredTags[i].tag.uid} key={i} />
      ))}
    </Box>
  </Typography>
}

export default Popular
