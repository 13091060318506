import React, { useState, useEffect } from 'react'
import Dotdotdot from "react-dotdotdot"
import Box from '@lib/ui/core/Box'
import Link from '@components/Link'
import Grid from "@lib/ui/core/Grid"
import Typography from '@lib/ui/core/Typography'
import Skeleton from "@lib/ui/core/Skeleton"
import Img from "gatsby-image"
import Button from '@lib/ui/core/Button'
import { navigate } from '@reach/router'
import { hasPath, path, useWith, or, identity, join } from "ramda"
import { linkResolver } from "@utils/link-resolver"
import useImpression from '@hooks/use-impression'
import { useStoreState, useStoreActions } from 'easy-peasy'

const renderImage = (images) => {
  const isCoverImage = hasPath(["url"], images)
  const isGatsbyImage = hasPath(["thumbnails", "thumbnail", "fluid"], images) || hasPath([0, "image", "thumbnails", "thumbnail", "fluid"], images)
  const thumbnailPath = isCoverImage ? path(["thumbnails", "thumbnail", "fluid"], images) : path([0, "image", "thumbnails", "thumbnail", "fluid"], images)
  const urlPath = isCoverImage ? path(["url"], images) : path([0, "image", "url"], images)
  const altPath = isCoverImage ? path(["alt"], images) : path([0, "image", "alt"], images)
  const aspectRatio = 1.5
  const width = [60, 120, 240, 360, 480]

  const parsedImage = isGatsbyImage
    ? {
      ...thumbnailPath,
      aspectRatio,
    }
    : {
      src: `${urlPath}&w=${
        width[2]
      }&h=${Math.floor(width[2] / aspectRatio)}&fit=max&q=50`,
      srcSet: join(
        ", ",
        width.map(
          (w) => `${urlPath}&w=${w}&h=${Math.floor(w / aspectRatio)}&fit=max&q=50 ${w}w`
        )
      ),
      sizes: "",
      aspectRatio,
    }
  // else {
  //  parsedImage = isGatsbyImage
  //   ? {
  //     ...path(
  //       ["thumbnails", "thumbnail", "fluid"],
  //       featured_images
  //     ),
  //     aspectRatio,
  //   }
  //   : {
  //     src: `${path(["url"], featured_images)}&w=${
  //       width[2]
  //     }&h=${Math.floor(width[2] / aspectRatio)}&fit=crop&q=50`,
  //     srcSet: join(
  //       ", ",
  //       width.map(
  //         (w) => `${path(
  //           ["url"],
  //           featured_images
  //         )}&w=${w}&h=${Math.floor(w / aspectRatio)}&fit=crop&q=50 ${w}w`
  //       )
  //     ),
  //     sizes: "",
  //     aspectRatio,
  //   }
  // }

  return (
    <Img fluid={parsedImage} alt={altPath} />
  )
}

const CtaBanner = ({ ctabanner, loading = false, fullWidth = false, eventCategory, eventLabel }) => {
  const {
    title,
    description,
    to,
    image,
    button_text,
    redirect_url,
  } = ctabanner || {}

  const toggleSearchDrawer = useStoreActions(
    ({ offerSearch }) => offerSearch.toggleSearchDrawer
  )

  const url = redirect_url.link_type === 'Document' ? linkResolver(redirect_url.document) : redirect_url.url
  const { observe, unobserve } = useImpression({ eventCategory: eventCategory, eventLabel: eventLabel, once: true, })

  useEffect(() => {
    return () => unobserve && unobserve()
  }, [])

  const handleClick = () => {
    toggleSearchDrawer(false)
    navigate(url)
  }

  const isLoadingOr = useWith(or, [identity, identity])(loading)
  return (
    <Box p={{ xs: "1rem", sm: "2rem" }} maxWidth={700} mx="auto" my={2} bgcolor="rgba(251, 231, 77, 0.15)" ref={observe}>
      <Grid container alignItems="stretch" spacing={fullWidth ? 0 : 2}>
          <Grid item xs={4} sm={4}>
            <Box mb={1.25} data-ele-name="offer-image" style={{ cursor: 'pointer' }} onClick={handleClick}>
              <Box borderRadius="1rem" bgcolor="grey.100" clone>
                {!loading ? (
                  renderImage(image)
                ) : (
                  <Box pb="100%" clone>
                    <Skeleton variant="rect" width="100%" />
                  </Box>
                )}
              </Box>
            </Box>
          </Grid>
          {/* Right Column */}
          <Grid item xs={8} sm={8}>
            <Box m={1.25} overflow="hidden" display="flex" data-ele-name="offer-title" style={{ cursor: 'pointer' }} onClick={handleClick}>
              <Box>
                <Typography
                  variant="h3"
                  clamp={2}
                  component="h3"
                >
                {title}
                </Typography>
              </Box>
              <Box ml="auto" overflow="hidden" minWidth="3rem" textAlign="right">
                <Typography variant="body1" component="span" style={{fontWeight: 'bold', color: '#CEA542'}}>贊助</Typography>
              </Box>
            </Box>
            <Box m={1.25} overflow="hidden" data-ele-name="offer-description" style={{ cursor: 'pointer' }} onClick={handleClick}>
                <Typography
                  variant="body1"
                  clamp={2}
                  component="p"
                >
                {description.text}
                </Typography>
            </Box>
          </Grid>
        </Grid>
        { button_text && (
        <Box display="flex" maxWidth={360} m="auto" mt={2} textAlign="center" justifyContent="center">
          <Button width={300} py={1.25} px={0} data-ele-name="search-cta-button" onClick={handleClick}>
              <Typography fontSize="inherit" align="center" component="span">{button_text}</Typography>
          </Button>
        </Box>
        )}
    </Box>
  )
}
export default CtaBanner
