/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react'
import Layout from '@layouts/MainLayout'
import { Button, Divider, Drawer, Typography, Box } from '@lib/ui/core';
import Container from '@layouts/Container'
import Selected from "@components/Selected"

const Item = ({ item, onClick, selected }) => {
  const { name, prismicId: id } = item
  const existedIndex = selected.findIndex(
    (selectedItem) => selectedItem.prismicId === id
  )
  return (
    <Box onClick={() => onClick(item)} style={{ cursor: 'pointer' }}>
      <Box display="flex" mx={{ xs: 2, sm: 9 }} alignItems="center">
        <Box py={{ xs: 1.5, sm: 2 }} flexGrow={{ xs: 1, sm: 0 }} mr={{ xs: 0, sm: 1 }}>
          <Typography>
            {name}
          </Typography>
        </Box>
        {existedIndex > -1 && <Box display="flex" alignItems="center"><Selected fontSize="1.25rem" /></Box> }
      </Box>
      <Divider />
    </Box>
  )
}

const FilterInnerDrawer = ({ showDrawer, toggleDrawer, shown, updateShown, items, chosen, updateChosen, handleDrawer, headerTitle }) => {
  const [selected, setSelected] = useState(chosen)

  useEffect(() => {
    setSelected(chosen)
  }, [chosen])

  const handleSelected = (selectedSubcat) => {
    const existedIndex = selected.findIndex(
      (subcat) => subcat.prismicId === selectedSubcat.prismicId
    )
    if (existedIndex > -1) {
      setSelected(selected.filter((subcat) => (subcat.prismicId !== selectedSubcat.prismicId)))
    } else {
      setSelected((prev) => [selectedSubcat, ...prev])
    }
  }

  const handleAdd = () => {
    updateChosen(selected)
    const filteredshow = shown.filter((item) => !Object.values(selected).map((selectedItem) => selectedItem.prismicId).includes(item.prismicId))
    updateShown([...selected, ...filteredshow])
    toggleDrawer(false)
    if (handleDrawer) { handleDrawer() }
  }

  const handleClose = () => {
    toggleDrawer(false)
  }


  return (
    <Drawer
      width="100%"
      open={showDrawer}
    >
      <Layout headerTitle={headerTitle} closeDrawer={handleClose}>
        {items ? items.map((item) => <Item key={item.prismicId} name={item.name} id={item.prismicId} uid={item.uid} item={item} onClick={handleSelected} selected={selected} />) : "no avaliable item"}
        <Box position="sticky" bottom={16}>
          <Container>
            <Box justifyContent="center" my={2} display="flex">
              <Button width="100%" maxWidth="360px" onClick={handleAdd}>添加</Button>
            </Box>
          </Container>
        </Box>
      </Layout>
    </Drawer>
  )
}

export default FilterInnerDrawer
