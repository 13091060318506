/* eslint-disable no-param-reassign */
import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { useStoreActions, useStoreState } from "easy-peasy"

import { Box, SvgIcon, Paper, InputBase, Typography } from "@lib/ui/core"
import Container from '@layouts/Container'

import { ReactComponent as SearchIcon } from "@assets/icons/search.svg"
import CardAdd from '@components/Search/FilterByCard/CardAdd'
import Popular from "@components/PopularTag"
import { getBanks, getCreditCards } from "@services/prismic"
import SearchDrawer from "./SearchDrawer"
import FiltersDrawer from "./FiltersDrawer"
import FiltersButton from "./FiltersButton"

// const Indicater = styled(Box)`
//   background-color: ${({ theme }) => `${theme.palette.actionText.active}`};
//   width: 0.5rem;
//   height: 0.5rem;
//   border-radius: 50%;
//   display: inline-block;
//   align-self: baseline;
// `

const StyledInputBase = styled(InputBase)`
  .MuiInputBase-input.Mui-disabled {
    color: rgb(0, 0, 0);
    :hover {
      cursor: pointer;
    }
  }
`
// const StyledButton = styled(Button)`
//   &:hover {
//     background: none;
//   }
// `

const Bar = () => {
  const toggleSearchDrawer = useStoreActions(
    ({ offerSearch }) => offerSearch.toggleSearchDrawer
  )

  const showSearchDrawer = useStoreState(
    ({ offerSearch }) => offerSearch.showSearchDrawer
  )

  const keyword = useStoreState(
    ({ offerSearch }) => offerSearch.searchPayload.q
  )


  return (
    <Box display="flex" height="100%" clone>
      <Box overflow="hidden" clone>
        <Paper
          borderRadius={{ xs: "2rem", sm: "1.25rem" }}
          elevation={0}
          display="flex"
          alignItems="center"
          bgcolor="grey.100"
        >
          <Box pl={{ xs: 1.5, sm: 2.5 }} pr={1} display="flex">
            <SvgIcon
              fontSize={{ xs: ".875rem", sm: "1rem" }}
              component={SearchIcon}
            />
          </Box>
          <Box flex="1" clone>
            <StyledInputBase
              data-ele-name="btn-search-open"
              fullWidth
              value={keyword || ""}
              fontSize={{ xs: ".875rem", sm: "1rem" }}
              placeholder="搜尋消費類型、地點、商戶"
              onClick={() => toggleSearchDrawer(true)}
              disabled
            />
          </Box>
          <SearchDrawer showSearchDrawer={showSearchDrawer} />
          {/* <Box py={1} height="100%">
            <Divider orientation="vertical" />
          </Box> */}
          {/* <StyledButton
            onClick={toggleFilter}
            disableRipple
            px={2}
            py={0}
            bgcolor="grey.100"
            height="100%"
            overflow="hidden"
          >
            <Box display="flex" alignItems="center">
              <SvgIcon
                fontSize={{ xs: ".875rem", sm: "1rem" }}
                component={Filter}
                mr={{ xs: 0.25, sm: 0.5 }}
              />
              <Typography variant="body1">信用卡</Typography>
              {searchedCards.length <= 0 ? (
                <Indicater />
              ) : (
                <Box
                  ml={{ xs: 0.25, sm: 0.5 }}
                  bgcolor="primary.main"
                  px={0.5}
                  borderRadius={5}
                >
                  {searchedCards.length}
                </Box>
              )}
            </Box>
          </StyledButton> */}
        </Paper>
      </Box>
    </Box>
  )
}

const SearchBar = () => {
  const toggleBankDrawer = useStoreActions(
    ({ offerSearch }) => offerSearch.toggleBankDrawer
  )
  const showBankDrawer = useStoreState(
    ({ offerSearch }) => offerSearch.showBankDrawer
  )

  const fetchSearchResult = useStoreActions(({ offerSearch }) => offerSearch.fetchSearchResult);
  // const searchedCardInfo = useStoreState(
  //   ({ offerSearch }) => offerSearch.firstCardInfo
  // )
  // const searchedCards = useStoreState(
  //   ({ offerSearch }) => offerSearch.searchPayload.creditcard_id
  // )
  const toggleFiltersDrawer = useStoreActions(({ offerSearch }) => offerSearch.toggleFiltersDrawer);

  const showFiltersDrawer = useStoreState(
    ({ offerSearch }) => offerSearch.showFiltersDrawer
  )

  const setChosenCard = useStoreActions(({ offerSearch }) => offerSearch.updateCardChosen);
  const chosenCard = useStoreState(({ offerSearch }) => offerSearch.cardChosen)

  const updateCardPayload = useStoreActions(({ offerSearch }) => offerSearch.updateCardPayload);

  const [selectBanks, setSelectBanks] = useState([])
  const [selectedCards, setSelectedCards] = useState(chosenCard)

  useEffect(() => {
    setSelectedCards(chosenCard)
  }, [chosenCard])

  const toggleCreditCard = async (uid, prismicId) => {
    const { results } = await getCreditCards({
      filter: {
        bankId: prismicId,
      },
      options: {
        pageSize: 100,
      },
    });
    setSelectBanks(selectBanks.map((item) => {
      if (item.uid === uid) {
        item.isShow = !item.isShow
        results.map((card) => {
          const newCard = {}
          card.bankId = prismicId
          return newCard
        })
        item.subcards = results
      }
      return item
    }))
  }

  const isCreditCardClicked = (prismicId) => selectedCards.find((o) => o.prismicId === prismicId)

  const handleChoose = (card) => {
    const isClicked = selectedCards.find((o) => o.prismicId === card.prismicId)
    if (isClicked) {
      // remove
      setSelectBanks(selectBanks.map((item) => {
        if (item.prismicId === card.bankId) {
          item.selected -= 1
        }
        return item
      }))
      setSelectedCards(selectedCards.filter((o) => o.prismicId !== card.prismicId))
    } else {
      setSelectBanks(selectBanks.map((item) => {
        if (item.prismicId === card.bankId) {
          item.selected += 1
        }
        return item
      }))
      setSelectedCards([...selectedCards, card])
    }
  }


  const toggleBankFilter = async () => {
    if (selectBanks.length === 0) {
      let { results } = await getBanks({
        filter: {
          displayOnOffer: true
        },
        options: {
          pageSize: 100,
        },
      })
      results = results.map((bank) => {
        bank.isShow = false
        bank.selected = chosenCard.filter((card) => bank.prismicId === card.bankId).length
        return bank
      })
      setSelectBanks(results)
    }
    toggleBankDrawer(true)
  }

  const handleClose = () => {
    // console.log("123")
    toggleBankDrawer(false)
    // setSelectedCards([])
    // setSelectBanks([])
  }

  const handleAddCreditCard = () => {
    setChosenCard(selectedCards)
    updateCardPayload(Object.values(selectedCards).map((card) => card.prismicId));
    fetchSearchResult()
    toggleBankDrawer(false)
    const closeBankDrawer = selectBanks.map((bank) => {
      bank.isShow = false
      return bank
    })
    setSelectBanks(closeBankDrawer)
  }

  const handleRevert = () => {
    updateCardPayload([]);
    setChosenCard([])
    fetchSearchResult();
    toggleBankDrawer(true);
  }

  const customHeader = () => (
    <Container>
      <Box display="flex" justifyContent="center" alignItems="center" position="relative">
        <Typography variant="subtitle2">請選擇發卡銀行</Typography>
        <Box mr={1} position="absolute" right={0} onClick={handleRevert} style={{ cursor: 'pointer' }}>
          <Typography color="secondary" variant="h4">還原</Typography>
        </Box>
      </Box>
    </Container>
  )

  return (
    <Box>
      <Box mb={1.25}>
        <Box height={{ xs: "2.5rem", sm: "3.125rem" }}>
          <Bar />
        </Box>
      </Box>
      <Box mb={{ xs: 1, sm: 2 }}>
        <Popular />
      </Box>
      <FiltersButton height={{ xs: "2.5rem", sm: "3.125rem" }} toggleBankFilter={toggleBankFilter} toggleFiltersDrawer={toggleFiltersDrawer} />
      {/* <FilterByBankDrawer
        bankList={bankList}
        showBankDrawer={showBankDrawer}
        searchedCards={searchedCards}
        searchedCardInfo={searchedCardInfo}
      /> */}
      <CardAdd
        customHeader={customHeader}
        showBankDrawer={showBankDrawer}
        selectBanks={selectBanks}
        selectedCards={selectedCards}
        onClose={handleClose}
        toggleCreditCard={toggleCreditCard}
        isCreditCardClicked={isCreditCardClicked}
        handleChoose={handleChoose}
        handleAddCreditCard={handleAddCreditCard}
      />
      <FiltersDrawer showFiltersDrawer={showFiltersDrawer} toggleFiltersDrawer={toggleFiltersDrawer} />
    </Box>
  )
}
export default SearchBar
