import { isEqual } from "lodash"

const defaultEventParams = {
  item_url: undefined,
  item_type: undefined,
  item_name: undefined,
  item_id: undefined,
  index: undefined,
  page: undefined,
  page_id: undefined,
  page_type: undefined,
  page_title: undefined,
  content_type: undefined,
}

const hasEvent = event => {
  return !!(window.dataLayer ? window.dataLayer : []).find(e =>
    isEqual(e, event)
  )
}

export const getPageType = pathname => {
  return "/"
    ? "homepage"
    : "/offers"
    ? "offer listing"
    : "/search"
    ? "search"
    : undefined
}

export const addGaEvent = (obj = {}) => {
  const {
    event,
    eventCategory,
    eventLabel,
    ga4Event,
    eventParams,
    once = false,
  } = obj
  if (process.env.NODE_ENV == "development") {
    console.log(
      event && ga4Event
        ? "[GA/GA4]"
        : event
        ? "[GA]"
        : ga4Event
        ? "[GA4]"
        : "[N/A]",
      obj
    )
  }
  window.dataLayer = window.dataLayer || []
  // GA Tracking
  if (event && eventCategory && eventLabel) {
    const e = { event, eventCategory, eventLabel }
    if (!hasEvent(e) || !once) {
      window.dataLayer.push(e)
    }
  }
  // GA4 Tracking
  if (ga4Event && eventParams) {
    const e = {
      event: "ga4_trigger",
      ga4Event,
      eventParams: { ...defaultEventParams, ...eventParams },
    }
    if (!hasEvent(e) || !once) {
      window.dataLayer.push(e)
    }
  }
}
