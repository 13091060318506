import { useInView } from 'react-cool-inview';
import { addGaEvent } from '@utils/ga-event';

export const useImpression = (gaEvent={}) => {
  const { observe, unobserve } = useInView({
    threshold: 1,
    onEnter: ({ unobserve, entry, scrollDirection }) => {
      if (gaEvent.once) {
        // console.log("unobserve: ", { event, eventCategory, eventLabel });
        unobserve();
      }
      addGaEvent({event: "impression", ...gaEvent});
    },
  });
  return {
    observe,
    unobserve,
  };
};

export default useImpression;