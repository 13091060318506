/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect } from 'react'
import Box from '@lib/ui/core/Box'
import Layout from '@layouts/MainLayout'
import Typography from '@lib/ui/core/Typography';
import Drawer from '@lib/ui/core/Drawer';
import { useStoreActions } from 'easy-peasy';
import Button from '@lib/ui/core/Button';
import Container from '@layouts/Container'
import SearchHeader from './SearchHeader'
import { TagLink } from '@components/Tag'
import { useStaticQuery, graphql } from 'gatsby'
import { parseSiteConfig } from '@services/prismic/helpers'
import CtaBanner from '@components/CtaBanner'
import { addGaEvent } from '@utils/ga-event';

const Tag = ({ text,uid }) => {
  const handleTagClick = () => {
    addGaEvent({
      ga4Event: "tag_click",
      eventParams: {
        item_name: text,
        content_type: "offer",
        item_type: "search_panel_tag",
      },
    });
  };

  return (
    <Box display="inline" mr={1.25}>
      {/* <TagLink to={linkResolver(text)}> */}
      <TagLink to={`/tag/${uid}`} item_name={text} content_type="offer" item_type="search_panel_tag" onClick={handleTagClick}>
        {text}
      </TagLink>
    </Box>
  );
};

const SearchDrawer = ({ showSearchDrawer }) => {
  const updateKeyword = useStoreActions(({ offerSearch }) => offerSearch.updateKeyword);
  const fetchSearchResult = useStoreActions(({ offerSearch }) => offerSearch.fetchSearchResult);
  const toggleSearchDrawer = useStoreActions(({ offerSearch }) => offerSearch.toggleSearchDrawer);

  const { allPrismicCtaBanner, allPrismicSiteConfig } = useStaticQuery(graphql`
    query {
      allPrismicCtaBanner(
        sort: {
          fields: [
            last_publication_date
          ]
          order: [
            DESC,
            DESC
          ]
        },
        filter: {
          data: {
            page: {
              eq: "search"
            }
          }
        }
      ) {
        totalCount
        nodes {
          data {
            image {
              url
            }
            description{
              text
            }
            button_text
            redirect_url {
              link_type
              target
              url
              document {
                ... on PrismicOffer {
                  id
                  uid
                  type
                  url
                  data {
                    offer_category {
                      uid
                    }
                    offer_subcategory {
                      uid
                    }
                  }
                }
                ... on PrismicPost {
                  id
                  uid
                  type
                  url
                  data {
                    post_category {
                      uid
                    }
                    post_subcategory {
                      uid
                    }
                  }
                }
              }
            }
            from(formatString: "YYYY-MM-DD")
            to(formatString: "YYYY-MM-DD")
            title
          }
        }
      }
      allPrismicSiteConfig {
        nodes {
          data {
            featured_tags {
              tag {
                document {
                  ... on PrismicTag {
                    id
                    data {
                      name
                    }
                    prismicId
                    uid
                  }
                }
              }
            }
          }
        }
      }
    }
  `);
  const featuredTags = parseSiteConfig(allPrismicSiteConfig.nodes[0]).featured_tags;
  const ctabannerDoc = allPrismicCtaBanner && allPrismicCtaBanner.nodes;
  const [ctaBannerData, setCtaBannerData] = useState([]);
  const tzoffset = (new Date()).getTimezoneOffset() * 60000
  const today = (new Date(Date.now() - tzoffset)).toISOString().substring(0, 10)
  const todayTime = new Date(today).getTime()

  const ctaFilter = ctabannerDoc.filter((cta) => new Date(cta.data.to).getTime() >= todayTime && new Date(cta.data.from).getTime() <= todayTime)

  useEffect(() => {
    setCtaBannerData(ctaFilter.sort(() => Math.random() - 0.5))
  }, [])

  return (
    <Drawer
      width="100%"
      open={showSearchDrawer}
    >
      <Layout customHeader={<SearchHeader />} hideAvatar>
        <Container data-ele-section="search-panel">
          <Box mt={{ xs: 5, sm: 8 }} mb={2}>
            <Typography variant="body1">
              熱門
            </Typography>
          </Box>
          <Box flexGrow={1} display="absolute" justifyContent="center" mb={{ xs: 2, sm: 5.5 }}>
            {featuredTags.map((tag, i) => (
              // <Grid item xs={3}>
              <Tag text={featuredTags[i].tag.name} uid={featuredTags[i].tag.uid} key={i} />
            ))}
          </Box>
          { ctaBannerData.length > 0 && (
            <CtaBanner ctabanner={ctaBannerData[0].data} eventCategory="search_cta_banner" eventLabel={location.href} />
          )}
        </Container>
      </Layout>
    </Drawer>
  );
}

export default SearchDrawer;
