import React, { useState } from 'react'
import styled from 'styled-components'
import Box from '@lib/ui/core/Box'
import Typography from '@lib/ui/core/Typography'
import SvgIcon from '@lib/ui/core/SvgIcon'
import Divider from '@lib/ui/core/Divider'
import Drawer from '@lib/ui/core/Drawer'
import Layout from '@layouts/MainLayout'
import { path } from 'ramda'
import { ReactComponent as Arrow } from '@assets/icons/back-arrow.svg'
import { useStoreActions, useStoreState } from 'easy-peasy'
import { getNeighborhoods } from '@services/prismic'
import { SearchHeader } from '../../SearchDrawer'
import FilterByNeighborhood from '../FilterInnerDrawer'

const StyledSvgIcon = styled(SvgIcon)`
  transform: rotate(180deg);
`

const Item = ({ name, id, onClick }) => {
  let total = 0
  const neighborhoodChosen = useStoreState(({ offerSearch }) => offerSearch.neighborhoodChosen)
  // eslint-disable-next-line no-return-assign
  neighborhoodChosen.map((neighborhood) => (path(['region', 'prismicId'], neighborhood) === id || path(['region', 'id'], neighborhood) === id) && (total += 1))
  return (
    <Box onClick={() => onClick(id)} style={{ cursor: 'pointer' }}>
      <Box display="flex" mx={{ xs: 2, sm: 7 }} alignItems="center">
        <Box py={{ xs: 1.5, sm: 2.5 }} flexGrow={1}>
          <Box display="flex">
            <Typography>
              {name}
            </Typography>
            {(total > 0) && (
            <Box
              ml={0.5}
              display="flex"
              alignItems="center"
              bgcolor="grey.100"
              px={0.5}
              borderRadius={5}
            >
              <Typography>{total}</Typography>
            </Box>
            )}
          </Box>
        </Box>
        <StyledSvgIcon fontSize={{ xs: ".875rem", sm: "1rem" }} component={Arrow} />
      </Box>
      <Divider />
    </Box>
  )
}


const FilterByRegion = ({ items, showDrawer, closeDrawer }) => {
  const showNeighborhoodDrawer = useStoreState(({ offerSearch }) => offerSearch.showNeighborhoodDrawer)
  const neighborhoodShown = useStoreState(({ offerSearch }) => offerSearch.neighborhoodShown)
  const updateNeighborhoodShown = useStoreActions(({ offerSearch }) => offerSearch.updateNeighborhoodShown);
  const neighborhoodChosen = useStoreState(({ offerSearch }) => offerSearch.neighborhoodChosen)
  const updateNeighborhoodChosen = useStoreActions(({ offerSearch }) => offerSearch.updateNeighborhoodChosen);
  const toggleNeighborhoodDrawer = useStoreActions(({ offerSearch }) => offerSearch.toggleNeighborhoodDrawer);

  const [neighborhood, setNeighborhood] = useState()

  const handleClick = async (id) => {
    const { results } = await getNeighborhoods({
      filter: {
        regionId: id,
      },
      options: {
        pageSize: 100,
      },
    });
    setNeighborhood(results);
    toggleNeighborhoodDrawer(true)
  }

  return (
    <Drawer
      width="100%"
      open={showDrawer}
    >
      <Layout customHeader={<SearchHeader />} hideAvatar>
        {items ? items.map((item) => <Item name={item.name} id={item.prismicId} uid={item.uid} key={item.uid} onClick={handleClick} />) : "no avaliable item"}
      </Layout>
      <FilterByNeighborhood shown={neighborhoodShown} handleDrawer={closeDrawer} showDrawer={showNeighborhoodDrawer} toggleDrawer={toggleNeighborhoodDrawer} updateShown={updateNeighborhoodShown} items={neighborhood} chosen={neighborhoodChosen} updateChosen={updateNeighborhoodChosen} headerTitle={path([0, "region", "name"], neighborhood)} />
    </Drawer>
  )
}

export default FilterByRegion
