import React from 'react'
import Typography from '@lib/ui/core/Typography'

// eslint-disable-next-line prefer-arrow-callback
const Tag = React.forwardRef(function Tag(props, ref) {
  const { children, ...rest } = props
  return <Typography ref={ref} {...rest}>#{ children }</Typography>
})

export default Tag
