/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react'
import { Box, Button, Divider, Typography, Drawer, Hidden } from '@lib/ui/core'
import Layout from '@layouts/MainLayout'
import { useStoreActions, useStoreState } from 'easy-peasy';
import Container from '@layouts/Container'
import { useStaticQuery, graphql } from 'gatsby'
import { parseSiteConfig, parseOfferSubcategory } from '@services/prismic/helpers'
import { hasPath } from 'ramda'
import { getRegions } from "@services/prismic"
import FilterBySubcat from './FilterInnerDrawer'
import FilterByNeighborhood from './FilterByNeighborhood'
import styled from 'styled-components'
import { navigate } from '@reach/router'
import { addGaEvent } from '@utils/ga-event';

const StyledTypography = styled(Typography)`
  :hover {
    cursor: pointer;
  }
`


const Section = ({ title, isLast, children, handleClick }) => (
  <>
    <Box mb={3}>
      <Box mb={2}>
        <Typography>{title}</Typography>
      </Box>
      <Box display="flex" flexWrap="wrap" alignItems="center">
        {children}
        <Box ml={0.5} mb={1} onClick={() => handleClick(true)} display="flex" alignItems="center" style={{ cursor: 'pointer' }}>
          <Typography color="secondary" variant="h4">更多</Typography>
        </Box>
      </Box>
    </Box>
    {isLast && <Hidden smUp><Divider /></Hidden>}
  </>
)
const FiltersDrawer = ({ showFiltersDrawer, bubblesLength = 7 }) => {
  const { allPrismicOfferSubcategory, allPrismicSiteConfig } = useStaticQuery(graphql`
  query {
    allPrismicSiteConfig {
      nodes {
        data {
          featured_offer_subcategories {
            offer_subcategory {
              document {
                ... on PrismicOfferSubcategory {
                  id
                  data {
                    name
                  }
                  prismicId
                  uid
                }
              }
            }
          }
          featured_neighborhoods {
            neighborhood {
              document {
                ... on PrismicNeighborhood {
                  id
                  data {
                    name
                    region {
                      id
                    }
                  }
                  prismicId
                }
              }
            }
          }
        }
      }
    }
    allPrismicOfferSubcategory {
      nodes {
        data {
          name
        }
        uid
        prismicId
      }
    }
  }
`)

  const { featured_offer_subcategories: featuredSubcategories, featured_neighborhoods: featuredNeighborhoods } = hasPath(['nodes'], allPrismicSiteConfig) && parseSiteConfig(allPrismicSiteConfig.nodes[0])
  const subcategories = parseOfferSubcategory(allPrismicOfferSubcategory.nodes)

  const toggleFiltersDrawer = useStoreActions(({ offerSearch }) => offerSearch.toggleFiltersDrawer);
  const fetchSearchResult = useStoreActions(({ offerSearch }) => offerSearch.fetchSearchResult);
  const toggleSubcatDrawer = useStoreActions(({ offerSearch }) => offerSearch.toggleSubcatDrawer);

  const showSubcatDrawer = useStoreState(({ offerSearch }) => offerSearch.showSubcatDrawer)
  const subcatShown = useStoreState(({ offerSearch }) => offerSearch.subcatShown)
  const updateSubcatShown = useStoreActions(({ offerSearch }) => offerSearch.updateSubcatShown);
  const subcatChosen = useStoreState(({ offerSearch }) => offerSearch.subcatChosen)
  const updateSubcatChosen = useStoreActions(({ offerSearch }) => offerSearch.updateSubcatChosen);
  const updateSubcatPayload = useStoreActions(({ offerSearch }) => offerSearch.updateSubcatPayload);

  const neighborhoodShown = useStoreState(({ offerSearch }) => offerSearch.neighborhoodShown)
  const updateNeighborhoodShown = useStoreActions(({ offerSearch }) => offerSearch.updateNeighborhoodShown);
  const neighborhoodChosen = useStoreState(({ offerSearch }) => offerSearch.neighborhoodChosen)
  const updateNeighborhoodChosen = useStoreActions(({ offerSearch }) => offerSearch.updateNeighborhoodChosen);
  const updateNeighborhoodPayload = useStoreActions(({ offerSearch }) => offerSearch.updateNeighborhoodPayload);
  const updatePageNumber = useStoreActions(({ offerSearch }) => offerSearch.updatePageNumber);

  const showRegionDrawer = useStoreState(({ offerSearch }) => offerSearch.showRegionDrawer)
  const toggleRegionDrawer = useStoreActions(({ offerSearch }) => offerSearch.toggleRegionDrawer);
  const updateFilteredBanner = useStoreActions(({ offerSearch }) => offerSearch.updateFilteredBanner)


  // const [subcatInBubbles, setSubcatInBubbles] = useState([])
  const existedIndex = (selected, check) => (selected.findIndex((item) => item.prismicId === check.prismicId))

  const [regions, setRegions] = useState()

  const handleClickRegion = async () => {
    // resetSelected();
    const { results } = await getRegions({
      options: {
        pageSize: 10,
      },
    })
    setRegions(results)
    toggleRegionDrawer(true)
  }


  useEffect(() => {
    if (subcatShown.length < bubblesLength && subcatShown.length < featuredSubcategories.length) {
      const additionSubcat = []
      featuredSubcategories.filter((subcategory) => !(existedIndex(subcatShown, subcategory.offer_subcategory) > -1)).slice(0, bubblesLength - subcatShown.length).map((subcategory) => additionSubcat.push(subcategory.offer_subcategory))
      // featuredSubcategories.slice(0, bubblesLength - subcatShown.length).map((subcategory) => console.log("subcatInBubbles", subcatInBubbles) && setSubcatInBubbles((rest) => [...rest, subcategory.offer_subcategory]))
      updateSubcatShown([...subcatShown, ...additionSubcat])
    }
  }, [subcatShown])


  useEffect(() => {
    if (neighborhoodShown.length < bubblesLength && neighborhoodShown.length < featuredNeighborhoods.length) {
      const additionLocation = []
      featuredNeighborhoods.filter((location) => !(existedIndex(neighborhoodShown, location.neighborhood) > -1)).slice(0, bubblesLength - neighborhoodShown.length).map((location) => additionLocation.push(location.neighborhood))
      updateNeighborhoodShown([...neighborhoodShown, ...additionLocation])
    }
  }, [neighborhoodShown])


  const handleSelectedSubcat = (selectedSubcat) => {
    addGaEvent({
      ga4Event: "offer_list_generic_filter_item_click", 
      eventParams: {
        item_name: selectedSubcat.name,
        item_type: "offer_filter_category",
      },
    })
    if (subcatChosen.length === 0) { return updateSubcatChosen([selectedSubcat]) }
    const index = existedIndex(subcatChosen, selectedSubcat)
    if (index > -1) {
      return updateSubcatChosen(subcatChosen.filter((subcat) => (subcat.prismicId !== selectedSubcat.prismicId)))
    }
    return updateSubcatChosen([selectedSubcat, ...subcatChosen])
  }


  const handleSelectedLocation = (selectedLocation) => {
    addGaEvent({
      ga4Event: "offer_list_generic_filter_item_click", 
      eventParams: {
        item_name: selectedLocation.name,
        item_type: "offer_filter_neighborhood",
      },
    })
    if (neighborhoodChosen.length === 0) { return updateNeighborhoodChosen([selectedLocation]) }
    const index = existedIndex(neighborhoodChosen, selectedLocation)
    if (index > -1) {
      return updateNeighborhoodChosen(neighborhoodChosen.filter((location) => (location.prismicId !== selectedLocation.prismicId)))
    }
    return updateNeighborhoodChosen([selectedLocation, ...neighborhoodChosen])
  }


  const toggleFilter = () => {
    updateSubcatPayload(Object.values(subcatChosen).map((subcat) => subcat.prismicId))
    updatePageNumber(1)
    updateNeighborhoodPayload(Object.values(neighborhoodChosen).map((location) => location.prismicId))
    fetchSearchResult()
    toggleFiltersDrawer(false)
    updateFilteredBanner(true)
  }

  const handleClose = () => {
    toggleFiltersDrawer(false)
  }

  const handleRevert = () => {
    updateSubcatChosen([])
    updateNeighborhoodChosen([])
  }
  const customHeader = () => (
    <Container>
      <Box display="flex" justifyContent="center" alignItems="center" position="relative">
        <Typography variant="subtitle2">篩選</Typography>
        <Box position="absolute" right={0} onClick={handleRevert} style={{ cursor: 'pointer' }}>
          <Typography color="secondary" variant="h4">還原</Typography>
        </Box>
      </Box>
    </Container>
  )

  const handleRegionDrawerClose = () => {
    toggleRegionDrawer(false)
  }

  const handleSubcatDrawerClose = () => {
    toggleSubcatDrawer(false)
  }

  return (
    <>
      <Drawer
        width="100%"
        open={showFiltersDrawer}
      >
        <Layout customHeader={customHeader} closeDrawer={handleClose}>
          <Container>
            <Box mt={{ xs: 1.5, sm: 2.5 }} color="actionText.active" onClick={handleClose} textAlign="right">
              <StyledTypography variant="h4">取消</StyledTypography>
            </Box>
            <Box mt={{ xs: 1.5, sm: 5.5 }} mb={{ xs: 5, sm: 15 }}>
              <Section title="優惠類型" handleClick={toggleSubcatDrawer}>
                {subcatShown.slice(0, 7).map((subcat) => <Box key={subcat.prismicId} mb={1} display="inline" bgcolor={(existedIndex(subcatChosen, subcat) > -1 && "primary.main") || "grey.100"} px={1.5} py={1} mr={1} borderRadius="2rem" onClick={() => handleSelectedSubcat(subcat)} style={{ cursor: 'pointer' }} item_name={subcat.name} item_type="offer_filter_category">{subcat.name}</Box>)}
              </Section>
              <Section title="地區" handleClick={handleClickRegion}>
                {neighborhoodShown.slice(0, 7).map((location) => <Box key={location.prismicId} mb={1} display="inline" bgcolor={(existedIndex(neighborhoodChosen, location) > -1 && "primary.main") || "grey.100"} px={1.5} py={1} mr={1} borderRadius="2rem" onClick={() => handleSelectedLocation(location)} style={{ cursor: 'pointer' }} item_name={location.name} item_type="offer_filter_neighborhood">{location.name}</Box>)}
              </Section>
            </Box>
            <Box display="flex" mb={{ xs: 2, sm: 5 }} justifyContent="center">
              <Button width="100%" maxWidth="360px" onClick={toggleFilter}>篩選</Button>
            </Box>
          </Container>
        </Layout>
      </Drawer>
      <FilterBySubcat showDrawer={showSubcatDrawer} toggleDrawer={toggleSubcatDrawer} shown={subcatShown} updateShown={updateSubcatShown} items={subcategories} chosen={subcatChosen} updateChosen={updateSubcatChosen} handleClose={handleSubcatDrawerClose} headerTitle="優惠類型" />
      <FilterByNeighborhood showDrawer={showRegionDrawer} closeDrawer={handleRegionDrawerClose} items={regions} />
    </>
  )
}

export default React.memo(FiltersDrawer)
