import { useDebouncedCallback } from 'use-debounce';

const useDebounce = (func, time) => {
  const [debouncedCallback] = useDebouncedCallback(
    // function
    (value) => {
      func(value);
    },
    // delay in ms
    time
  );
  return debouncedCallback;
}

export default useDebounce
