import React from 'react'
import { map, hasPath } from 'ramda'
import Box from '@lib/ui/core/Box'
import Typography from '@lib/ui/core/Typography'
import { linkResolver } from '@utils/link-resolver'
import { TagLink } from '@components/Tag'

const TagList = ({ tags, height, variant, handleTagClick=()=>{} }) => (
  <Typography component={Box} variant={variant} height={height} overflow="hidden">
    <Box display="flex" flexWrap="wrap">
      { Array.isArray(tags) && map(({ tag }) => (
        hasPath(['name'], tag) && (
          <Box key={tag.id} mr={1.25} display="inline" onClick={() => handleTagClick(tag)}>
            <TagLink variant={variant} to={linkResolver(tag)}>{tag.name}</TagLink>
          </Box>
        )
      ), tags)}
    </Box>
  </Typography>
)

TagList.defaultProps = {
  height: "1.5em",
  variant: "body1",
}

export default TagList
