import React from 'react'
import Link from '@components/Link'

// eslint-disable-next-line prefer-arrow-callback
const TagLink = React.forwardRef(function TagLink(props, ref) {
  const { children, ...rest } = props
  return <Link ref={ref} {...rest} data-ele-name="tag">#{ children }</Link>
})

export default TagLink
