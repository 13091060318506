import React from "react"
import { SvgIcon } from "@lib/ui/core/"
import styled from "styled-components"
import CheckIcon from '@material-ui/icons/Check';

const SelectIcon = styled(SvgIcon)`
  border-radius: 50%;
  align-self: baseline;
  background-color: ${({ theme }) => `${theme.palette.primary.main}`};
  position: relative;
  font-size: ${({ fontSize }) => `${fontSize}`};
  z-index: 1;
  padding: 2px;
`
const Selected = ({ fontSize }) => <SelectIcon component={CheckIcon} fontSize={fontSize} />

export default Selected
