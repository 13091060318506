import React from 'react'
import styled from 'styled-components'
import { useStoreActions } from 'easy-peasy';
import InputBase from '@lib/ui/core/InputBase'
import Box from '@lib/ui/core/Box'
import Typography from '@lib/ui/core/Typography'
import Paper from '@lib/ui/core/Paper'
import SvgIcon from '@lib/ui/core/SvgIcon'
// import Popper from '@material-ui/core/Popper';
import { ReactComponent as SearchIcon } from '@assets/icons/search.svg'
// import Autocomplete from '@lib/ui/core/Autocomplete';
// import AccessTimeIcon from '@material-ui/icons/AccessTime';
// import CloseIcon from '@material-ui/icons/Close';
// import Divider from '@lib/ui/core/Divider'
import useDebounce from '@utils/debounce';

// const StyledPopper = styled(Popper)`
//   @media only screen and (max-width: 375px) {
//     top: 18px !important;
//     left: -5px !important;
//     width: 100% !important;
//   }
// `

// const StyledAutocomplete = styled(Autocomplete)`
//   .MuiAutocomplete-listbox{
//     padding: 0;
//   }
//   .MuiAutocomplete-option{
//     padding: 0;
//     min-height: auto;
//   }
// `

const StyledTypography = styled(Typography)`
  :hover {
    cursor: pointer;
  }
`

// const PopperComponent = (props) => (
//   <StyledPopper {...props} />
// )

// const PaperComponent = (props) => (
//   <Paper {...props} elevation={12} />
// )

const SearchHeader = () => {
  // const [keyword, setKeyword] = useState(null);
  const fetchAutocomplete = useStoreActions(({ offerSearch }) => offerSearch.fetchAutocomplete);
  // const deleteSearchHistory = useStoreActions(({ session }) => session.deleteSearchHistory);
  const updateKeyword = useStoreActions(({ offerSearch }) => offerSearch.updateKeyword);
  const updateSubcatChosen = useStoreActions(({ offerSearch }) => offerSearch.updateSubcatChosen)
  const updateSubcatPayload = useStoreActions(({ offerSearch }) => offerSearch.updateSubcatPayload);
  const updateNeighborhoodPayload = useStoreActions(({ offerSearch }) => offerSearch.updateNeighborhoodPayload);
  const updateNeighborhoodChosen = useStoreActions(({ offerSearch }) => offerSearch.updateNeighborhoodChosen)
  const updateCardChosen = useStoreActions(({ offerSearch }) => offerSearch.updateCardChosen)
  const updateCardPayload = useStoreActions(({ offerSearch }) => offerSearch.updateCardPayload)
  const updatePageNumber = useStoreActions(({ offerSearch }) => offerSearch.updatePageNumber)
  const fetchSearchResult = useStoreActions(({ offerSearch }) => offerSearch.fetchSearchResult);
  // const suggestion = useStoreState(({ offerSearch }) => offerSearch.autocompleteSuggestion);
  // const history = useStoreState(({ session }) => session.search.history);
  const toggleSearchDrawer = useStoreActions(({ offerSearch }) => offerSearch.toggleSearchDrawer);
  const toggleRegionDrawer = useStoreActions(({ offerSearch }) => offerSearch.toggleRegionDrawer);

  const DebouncedFetchAutocomplete = useDebounce(fetchAutocomplete, 500);

  const handleCancel = () => {
    toggleRegionDrawer(false)
    toggleSearchDrawer(false)
  }

  return (
    <Box height="100%" width="100%" display="flex" alignItems="center" justifyContent="center">
      <Box style={{ width: 'calc(100% - 36px)', height: "100%" }}>
        <Box display="flex" alignItems="center" height="100%">
          <Paper borderRadius={{ xs: "2rem", sm: "1.25rem" }} mr={2} height="100%" flexGrow={1} display="flex" alignItems="center" elevation={0} bgcolor="grey.100">
            <InputBase
              width="100%"
              fontSize="body1.fontSize"
              autoFocus
            // value={keyword}
              startAdornment={(
                <Box pl={{ xs: 3, sm: 2.5 }} pr={1} display="flex">
                  <SvgIcon fontSize={{ xs: ".875rem", sm: "1rem" }} component={SearchIcon} />
                </Box>
                )}
              onChange={(e) => {
              // setKeyword(e.target.value)
                DebouncedFetchAutocomplete(e.target.value)
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  updateKeyword(e.target.value)
                  updateSubcatChosen([])
                  updateSubcatPayload([])
                  updateNeighborhoodChosen([])
                  updateNeighborhoodPayload([])
                  updateCardChosen([])
                  updatePageNumber(undefined)
                  updateCardPayload([])
                  updateCardChosen([])
                  fetchSearchResult()
                  toggleSearchDrawer(false)
                  toggleRegionDrawer(false)
                }
              }}
            />
          </Paper>
        </Box>
      </Box>
      {/* <StyledAutocomplete
        style={{ width: 'calc(100% - 36px)', height: "100%" }}
        borderRadius={{ xs: 0, sm: "1.25rem" }}
        options={suggestion.length > 0 ? suggestion : history}
        getOptionLabel={(options) => options}
        filterOptions={(options) => options}
        // SelectProps
        freeSolo
        PaperComponent={PaperComponent}
        PopperComponent={PopperComponent}
        renderInput={(params) => (
          <Box display="flex" alignItems="center" height="100%">
            <Paper borderRadius="1.25rem" mr={2} height="100%" flexGrow={1} display="flex" alignItems="center" elevation={0} bgcolor="grey.100">
              <InputBase
                {...params}
                width="100%"
                fontSize="body1.fontSize"
                autoFocus
                ref={params.InputProps.ref}
                value={keyword}
                startAdornment={(
                  <Box pl={{ xs: 3, sm: 2.5 }} pr={1} display="flex">
                    <SvgIcon fontSize={{ xs: ".875rem", sm: "1rem" }} component={SearchIcon} />
                  </Box>
                )}
                onChange={(e) => {
                  setKeyword(e.target.value)
                  DebouncedFetchAutocomplete(e.target.value)
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    updateKeyword(e.target.value)
                    fetchSearchResult()
                    toggleSearchDrawer(false)
                  }
                }}
              />
            </Paper>
          </Box>
        )}
        renderOption={(option) => (
          <Box
            display="block"
            width="100%"
            onClick={() => {
              updateKeyword(option.name);
              fetchSearchResult();
              toggleSearchDrawer(false);
            }}
          >
            <Box display="flex" width="100%" alignItems="center" py={1.5} px={2}>
              <Box pr={1} color="text.secondary" display="flex">
                <SvgIcon color="inherit" fontSize={{ xs: ".875rem", sm: "1rem" }} component={suggestion.length === 0 ? AccessTimeIcon : SearchIcon} />
              </Box>
              <Box flexGrow={1}>
                <Typography variant="body1">{option.name}</Typography>
              </Box>
              {suggestion.length === 0 && (
              <Box pr={1} color="text.secondary">
                <SvgIcon
                  color="inherit"
                  fontSize={{ xs: ".875rem", sm: "1rem" }}
                  component={CloseIcon}
                  onClick={(e) => {
                    e.stopPropagation()
                    deleteSearchHistory(option.name);
                  }}
                />
              </Box>
              )}
            </Box>
            <Divider />
          </Box>
        )}
      /> */}
      <Box color="actionText.active" onClick={handleCancel} clone>
        <StyledTypography variant="h4">取消</StyledTypography>
      </Box>
    </Box>
  )
}

export default SearchHeader
