import React, { useState, useEffect } from 'react'
import { Box, ButtonBase, Typography, Divider, SvgIcon } from '@lib/ui/core'
import { ReactComponent as Filter } from "@assets/icons/filter.svg"
import CreditCardIcon from '@material-ui/icons/CreditCard'
import { useStoreState } from 'easy-peasy';
import { Location } from '@reach/router';
import { addGaEvent, getPageType } from '@utils/ga-event';

const ButtonLayout = ({ children, onClick, number, ...rest }) => (
  <Box flexGrow={1} display="flex" justifyContent="center" width="100%" height="100%" clone>
    <ButtonBase onClick={onClick} {...rest}>
      <Box display="flex" alignItems="center" mr={0.5}>
        {children}
      </Box>
      {(number > 0) && (
      <Box
        display="flex"
        alignItems="center"
        bgcolor="primary.main"
        px={0.5}
        borderRadius={5}
      >
        <Typography>{number}</Typography>
      </Box>
      )}
    </ButtonBase>
  </Box>
)

const FiltersButton = ({ height, toggleBankFilter, toggleFiltersDrawer, location }) => {
  const searchPayload = useStoreState(({ offerSearch }) => offerSearch.searchPayload)
  const [number, setNumber] = useState(0)
  const [cardNumber, setCardNumber] = useState(0)

  const handleFilterClick = () => {
    toggleFiltersDrawer(true)
    addGaEvent({
      ga4Event: "offer_list_generic_filter_panel_click",
      eventParams: {
        page: location.pathname + location.search,
        page_type: getPageType(location.pathname),
      },
    })
  }

  const handleBankClick = () => {
    toggleBankFilter()
    addGaEvent({
      ga4Event: "offer_list_card_filter_panel_click",
      eventParams: {
        page: location.pathname + location.search,
        page_type: getPageType(location.pathname),
      },
    })
  }

  useEffect(() => {
    setNumber(searchPayload.offer_subcategory_id.length + searchPayload.neighborhood_id.length)
    setCardNumber(searchPayload.creditcard_id.length)
  }, [searchPayload])


  return (
    <Box boxShadow={9} borderRadius={{ xs: "2rem", sm: "1.25rem" }} display="flex" height={height} alignItems="center">
      <ButtonLayout onClick={handleFilterClick} number={number}  page="/" page_type="homepage">
        <SvgIcon
          fontSize={{ xs: ".875rem", sm: "1rem" }}
          component={Filter}
          mr={0.5}
        />
        <Typography>
          篩選
        </Typography>
      </ButtonLayout>
      <Box py={1} height={height}>
        <Divider orientation="vertical" />
      </Box>
      <ButtonLayout data-ele-name="btn-card-filter" onClick={handleBankClick} number={cardNumber} page="/,/offers,/offers{searchPayload}" page_type="homepage,offer listing,search">
        <SvgIcon fontSize="1.25rem" component={CreditCardIcon} mr={0.5} />
        <Typography>
          信用卡
        </Typography>
      </ButtonLayout>
    </Box>
  )
}

export default props => (
  <Location>
    {locationProps => <FiltersButton {...locationProps} {...props} />}
  </Location>
);
// export default 
